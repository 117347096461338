import React, { useState, useEffect, useCallback } from 'react';
import { API_BASE_URL } from '../config/connect';
import add_banner_img01 from '../assets/img/images/add_banner_img01.jpg';
import jo_img from '../assets/img/images/jo_img.jpg';

function Adservices() {
	const [ads, setAdsservice] = useState([]);
	const [error, setError] = useState(null);

	const getAllAdsServices = useCallback(() => {
		return fetch(`https://africamarkets.net/ghmarketconnect/?ghw=adsservices&svg=adsservices`)
			.then((response) => response.json())
			.then((json) => {
				const uniqueAds = json['results']
					.filter(
						(ad, index, self) =>
							index === self.findIndex((a) => a.image === ad.image)
					)
					.slice(0, 4);
				setAdsservice(uniqueAds);
			})
			.catch((error) => {
				console.error('Error fetching ads:', error);
				setError('Failed to load ads. Please try again later.');
			});
	}, []);

	useEffect(() => {
		getAllAdsServices();
	}, [getAllAdsServices]);

	
	const renderAdColumn = (ad) => (
		<div
			className='row'
			key={ad.id}>
			<div className='col-md-12'>
				<div className='category-item-wrap mb-10'>
					<h4 className='title'>{ad.heading}</h4>
					<ul className='category-item-list'>
						{[...Array(2)].map((_, idx) => (
							<li
								className='category-item'
								key={idx}>
								<a href='/profservices'>
									<img
										src={ad.image}
										alt={`Ad for ${ad.heading}`}
									/>
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);

	return (
		<div>
			<section className='choose-product-area pt-80 pb-70'>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-lg-12'>
							<div className='section-title text-center mb-40'>
								<h2 className='title'>New Brands</h2>
							</div>
						</div>
					</div>
					<div className='row justify-content-center'>
						{/* Static Banner */}
						<div className='col-xl-3 col-lg-4 col-md-6 col-sm-8'>
							<div className='add-banner-container fade-in-out'>
								<div className='add-banner'>
									<div className='add-banner-img'>
										<a
											href='/profservices'
											className='add-banner-img-link'>
											<img
												src={add_banner_img01}
												alt='Banner for Top Clothing'
											/>
										</a>
									</div>
									<div className='add-banner-content'>
										<span>On the weekend</span>
										<h2 className='title'>Top Clothing</h2>
										<a
											href='/profservices'
											className='btn btn-two'>
											Shop Now
										</a>
									</div>
								</div>
							</div>
						</div>

						{/* Dynamic Ads */}
						<div className='col-xl-3 col-lg-4 col-md-6 col-sm-8'>
							{ads.length > 0 ? ads.slice(0, 2).map(renderAdColumn) : null}
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 col-sm-8'>
							{ads.length > 0 ? ads.slice(2, 4).map(renderAdColumn) : null}
						</div>

						{/* Welcome Box */}
						<div className='col-xl-3 col-lg-5 col-md-6 col-sm-8'>
							<div className='join-olle-wrap'>
								<div className='icon'>
									<a href='/'>
										<i className='fa-solid fa-user'></i>
									</a>
								</div>
								<h3 className='title'>Welcome to AfricaMarkets</h3>
								<div className='join-btn'>
									<a
										target='blank'
										href='https://gh.africamarkets.net/vendorpanel/index.php?action=Login'
										className='btn'>
										Vendor Sign up
									</a>
								</div>
								<a
									target='blank'
									href='https://gh.africamarkets.net/vendorpanel/index.php?action=Login'>
									<img
										src={jo_img}
										alt='Join AfricaMarkets'
									/>
								</a>
							</div>
						</div>
					</div>
					{/* Error Message */}
					{error && (
						<div className='row justify-content-center'>
							<p className='text-danger'>{error}</p>
						</div>
					)}
				</div>
			</section>
		</div>
	);
}

export default Adservices;
