import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import "../assets/css/Blackfriday.css";
import BlackFriday from "../components/BlackFriday";
import Trendingproducts from "../components/Trendingproducts";
function Blackfriday() {
  return (
    <div>
      <Header />

      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                <Trendingproducts>Trending Products</Trendingproducts>
                </aside>
              </div>

              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap mb-35">
                  <div className="shop-top-left">
                    <h5 className="title">Black Friday Specials</h5>
                  </div>
                  <div className="shop-top-right">
                    <ul>
                      <li>View</li>
                      <li className="active">
                        <NavLink to="">
                          <i className="fa-solid fa-table-cells"></i>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="">
                          <i className="fa-solid fa-bars"></i>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <BlackFriday></BlackFriday>
                </div>

                <div className="shop-bottom-wrap">
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      <form action="#">
                        {/* Sorting options can go here */}
                      </form>
                    </div>
                    <div className="shop-bottom-right">
                      <form action="#">
                        {/* Sorting options can go here */}
                      </form>
                      <ul>
                        <li className="active">
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Blackfriday;
