// import React from "react";
import React, { useEffect, useState } from "react";
// import {API_BASE_URL} from "../config/connect";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import logo from "../assets/img/logo/logo.png";
import logo from "../assets/img/logo/ghmarketlogo.png";
import dropdownicon from "../assets/img/images/dropdownarrow.png";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import logouticon from "../assets/img/images/power-off.png";
import ordersicon from "../assets/img/images/choices.png";
import reseticon from "../assets/img/images/reset-password.png";
import accountsicon from "../assets/img/images/user.png";
import messsageicon from "../assets/img/images/email.png";
import { API_BASE_URL } from "../config/connect";

function Topsearch() {
  const cartProducts = useSelector((state) => state.cart);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [msgCount,setMsgCount] = useState(0)
  const notify = (msg) => toast.success(msg);
  const navigate = useNavigate();

  const handleSearchClick = (e) => {
    // You can perform a search operation here, for simplicity, we'll just navigate to the related products page
    e.preventDefault();
    //  console.log(searchTerm);
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=allproduct&searchword=${searchTerm}`);
    //     if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    //     const json = await response.json();
    //     setData(json.results);
    //     setCache(cacheKey, json.results);
    //   } catch (error) {
    //     if (error.name !== "AbortError") {
    //       setError(error); // Only set error state if it's not an abort error
    //     } else if(error.name === "AbortError"){
    //       console.log("cancelled")
    //     }
    //   } finally {
    //     setLoading(false);
    //   }
    // }
    // setSearchTerm(searchTerm); 
    // Reset the input field
    // console.log(searchTerm)
    localStorage.setItem("search", searchTerm);
    navigate(`/relatedproduct?search=${encodeURIComponent(searchTerm)}`);
  };

  const handleSearch = (e) => {
    // You can perform a search operation here, for simplicity, we'll just navigate to the related products page
    // e.preventDefault();
    // console.log(e);
    e === ""
      ? localStorage.setItem("search", "")
      : localStorage.setItem("search", searchTerm);
    setSearchTerm(e);
    // console.log(searchTerm);
    // setSearchTerm(searchTerm); // Reset the input field
    // localStorage.setItem("search", searchTerm);
  };

  const getMessages = () => {
    fetch(
       `https://africamarkets.net/ghmarketconnect/?ghw=login&svg=get_messages&email=${localStorage.getItem("username")} `

    )
      .then((response) => response.json())
      .then((json) => {
        if (json["results"] && json["results"].length > 0) {
          // console.log(json["results"][0]);
          setMsgCount(json["results"].length)
        }
      });
  };

  useEffect(() => {
    const searching = localStorage.getItem("search");
    setSearchTerm(searching);

    // Check if user is already logged in
    const username = localStorage.getItem("username");
    if (username) {
      setIsLoggedIn(true);
      setFirstName(localStorage.getItem("firstname"));
      getMessages()
    }
  }, [firstname]);

  // handle logout events from the local storage
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("username");
    localStorage.removeItem("firstname");
    localStorage.removeItem("customcode");
  };

  return (
    <div>
      <div className="header-search-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-2 col-lg-3">
              <div className="logo">
                <NavLink to="/">
                  {/* <img src={logo} alt="logo" /> */}
                  <div className="logoimg"></div>
                </NavLink>
              </div>
            </div>
            <div className="col-xl-10 col-lg-9">
              <div className="d-block d-sm-flex align-items-center justify-content-end">
                <div className="header-search-wrap">
                  <form action="#" onSubmit={handleSearchClick}>
                    <input
                      type="text"
                      placeholder="Search for product..."
                      value={searchTerm}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    {/* <select className="custom-select" defaultValue="0">
                      <option value="0">All Categories</option>
                      <option>Women's Clothing</option>
                      <option>Men's Clothing</option>
                      <option>Luggage & Bags</option>
                    </select> */}
                    <button>
                      <i className="fas fa-search"></i>
                    </button>

                    {/* {searchResults.length > 0 && (
        <div>
          <h2>Search Results:</h2>
          <ul>
            {searchResults.map((result) => (
              <li key={result.id}>{result.name}</li>
            ))}
          </ul>
        </div>
      )} */}
                  </form>
                </div>
                <div className="header-action">
                  <ul>
                    <li>
                      <Link to="/wishlist">
                        <i className="far fa-star"></i>Wishlist
                      </Link>
                    </li>
                    <li>
                      <Link to="/compare">
                        <i className="fas fa-redo"></i>Compare
                      </Link>
                    </li>
                    <li className="header-shop">
                      <Link to="/cart">
                        <i className="flaticon-shopping-bag"></i>Cart
                        <span className="cart-count">
                          {cartProducts.productsNumber}
                        </span>
                      </Link>
                    </li>
                    {isLoggedIn ? (
                      <li class="header-sine-in">
                        <div class="dropdown">
                          <NavLink href="#">
                            <i class="flaticon-user "></i>
                            <p style={{ display: "inline-block" }}>
                              Welcome, <span>{firstname}</span>
                            </p>
                            <img
                              src={dropdownicon}
                              style={{
                                width: "18px",
                                height: "auto",
                                marginLeft: "25px",
                              }}
                              alt="dropdownicon"
                            />
                          </NavLink>
                          <div class="dropdown-content">
                            <NavLink to="/account">
                              <img
                                className="icon-dropdown"
                                src={accountsicon}
                                alt="account-imge"
                              />
                              Account
                            </NavLink>
                            <NavLink to="/orders">
                              {" "}
                              <img
                                className="icon-dropdown"
                                src={ordersicon}
                                alt="orders-imge"
                              />
                              Orders
                            </NavLink>
                            <NavLink to="/messages">
                              <div className="d-flex ">
                                <div>
                                  {" "}
                                  <img
                                    className="icon-dropdown"
                                    src={messsageicon}
                                    alt="orders-imge"
                                  />
                                  My Messages
                                </div>
                                <div className="counter-messages">{msgCount}</div>
                              </div>
                            </NavLink>
                            <NavLink to="/resetpass">
                              <img
                                className="icon-dropdown"
                                src={reseticon}
                                alt="reset-imge"
                              />
                              Password Reset
                            </NavLink>

                            <NavLink to="/login" onClick={handleLogout}>
                              <img
                                className="icon-dropdown"
                                src={logouticon}
                                alt="paswword-imge"
                              />
                              Logout
                            </NavLink>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li class="header-sine-in">
                        <div class="dropdown">
                          <NavLink to="/login">
                            <i class="flaticon-user "></i>
                            <p style={{ display: "inline-block" }}>
                              Customer Login{" "}
                            </p>
                          </NavLink>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topsearch;
