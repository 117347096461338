import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE_URL } from "../config/connect";

const StarRating = ({ prodcode }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [isRated, setIsRated] = useState(false); // New state to disable after rating

  const notify = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  useEffect(() => {
    // Retrieve the rating from local storage when the component mounts
    const storedRating = localStorage.getItem(`rating_${prodcode}`);
    if (storedRating) {
      setRating(parseInt(storedRating));
      setIsRated(true); // Disable stars if already rated
    }
  }, [prodcode]);

  const handleRatingClick = async (ratingValue) => {
    if (isRated) return; // Prevent rating if already rated

    try {
      setRating(ratingValue);
      setIsRated(true); // Disable further rating

      // Save the rating to local storage
      localStorage.setItem(`rating_${prodcode}`, ratingValue);

      const data = { rating: ratingValue, productCode: prodcode };
      console.log("Rating submitted:", ratingValue);

      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=ratingpost`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("Post request successful:", responseData);

      if (responseData.success === true) {
        notify("Rating successfully submitted");
      } else if (responseData.error === "alreadyexists") {
        notifyError("You have already rated this product");
      }
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <label key={index}>
            <input
              style={{ display: "none" }}
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => !isRated && handleRatingClick(ratingValue)} // Disable clicks if rated
            />
            <FaStar
              className="star"
              color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
              size={18}
              onMouseEnter={() => !isRated && setHover(ratingValue)} // Only allow hover if not rated
              onMouseLeave={() => !isRated && setHover(0)} // Only allow hover reset if not rated
              style={{ cursor: isRated ? "not-allowed" : "pointer" }} // Change cursor to indicate disabled state
            />
          </label>
        );
      })}
      <ToastContainer />
    </div>
  );
};

export default StarRating;
