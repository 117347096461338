import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../config/connect";

const CheckoutPaySummary = ({
  handlePayment,
  payLoading,
  userInfo,
  payInfo,
  trackInfo,
  clientRegion,
  sellerRegion,
  sendcourierfee,
  sendtaxamount,
  deliverystatus,
  zoneprice
}) => {
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [enableBtn, setEnableBtn] = useState(false);
  const cartProducts = useSelector((state) => state.cart);
  const cartItems = JSON.parse(localStorage.getItem("cart"));
  const [interregioncourierfee, setInterFee] = useState(0);
  const [intraregioncourierfee, setIntraFee] = useState(0);

  const getCartProducts =
    cartProducts?.products?.length > 0 ? cartProducts : cartItems;
  // console.log(sellerRegion);
  // console.log(deliverystatus)
  const getTaxes = async () => {
    const res = await fetch(`https://africamarkets.net/ghmarketconnect/?ghw=processpayment&svg=gettaxes`);
    const response = await res.json();
    return response;
  };
  const getPlatformFees = () => {
    fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=deliveryfees`)
      .then((response) => response.json())
      .then((json) => {
        // console.log(json['results'][0]);
        if (json["results"] && json["results"].length > 0) {
          setIntraFee(json["results"][0].intraregioncourierfee);
          setInterFee(json["results"][0].interregioncourierfee);
        }
      });
  };

  useEffect(() => {
    getPlatformFees();
    const taxes = getTaxes();
    taxes.then((js) => {
      setTaxPercentage(js["data"]);
      setTaxAmt(((js["data"] * getCartProducts?.productsAmt) / 100).toFixed(2));
      taxAmt ? sendtaxamount(parseFloat(taxAmt)) : sendtaxamount(0);
    });

    // Calculate total delivery fee for all products
    // let totalDeliveryFee = 0;
    // Object.entries(sellerRegion || {}).forEach(([productCode, region]) => {
    //   const status = deliverystatus[productCode]; // Get deliverystatus value for the current product
    // // console.log(status);
    //   if (region === clientRegion && status !== '1') {
    //     totalDeliveryFee += intraregioncourierfee;
    //     // console.log(totalDeliveryFee);
    //   } else if (clientRegion !== '' && region !== clientRegion && status !== '1') {
    //     totalDeliveryFee += interregioncourierfee;
    //   } else if (region === clientRegion && status === '1') {
    //     totalDeliveryFee = 0;
    //     // console.log("true");
    //   } else if (clientRegion !== '' && region !== clientRegion && status === '1') {
    //     totalDeliveryFee = 0;
    //     // console.log("trueman");
    //   } else {
    //     totalDeliveryFee = 0;
    //   }
    // });


    // Set total delivery fee
    setDeliveryFee(zoneprice);
    // sendcourierfee(parseFloat(totalDeliveryFee));
    //  // Send fees back to parent
    //  deliveryFee ? sendcourierfee(parseFloat(totalDeliveryFee)) : sendcourierfee(0) ;


    if (
      typeof userInfo !== "undefined" &&
      userInfo !== null &&
      typeof payInfo !== "undefined" &&
      payInfo !== null
    )
      Object.keys(userInfo).length > 0 && Object.keys(payInfo).length > 0
        ? setEnableBtn(false)
        : setEnableBtn(true);
  }, [
    taxAmt,
    getCartProducts,
    taxPercentage,
    userInfo,
    payInfo,
    clientRegion,
    sellerRegion,
    deliveryFee,
    interregioncourierfee,
    intraregioncourierfee,
    sendcourierfee,
    sendtaxamount,
    deliverystatus,
  ]);
  const safeParseFloat = (value) => {
    // Convert the value to a float or return 0 if it's NaN
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };
  return (
    <>
      <div className="col-sm-3">
        <div className="card">
          <div className="card-header">Summary</div>
          <div className="card-body">
            <div className="coupon-product-content">
              <div className="cart-summary-item">
                <div className="cart-summary-item-label">Subtotal</div>
                <div className="cart-summary-item-content">
                  GH₵ {(getCartProducts?.productsAmt).toFixed(2)}
                </div>
              </div>
              {taxAmt > 0 && (
                <div className="cart-summary-item mt-2">
                  <div className="cart-summary-item-label">Tax</div>
                  <div className="cart-summary-item-content">GH₵ {taxAmt}</div>
                </div>
              )}
              {clientRegion && sellerRegion && deliverystatus && (
                <div className="cart-summary-item">
                  <div className="cart-summary-item-label">Delivery Fees</div>
                  <div className="cart-summary-item-content">
                    GH₵ {deliveryFee}
                  </div>
                </div>
              )}

              <hr className="mt-15 mb-15" />

              <div className="cart-summary-item">
                <div className="cart-summary-item-label">Total</div>
                <div className="cart-summary-item-content">
                  GH₵{" "}
                  {(
                    safeParseFloat(getCartProducts?.productsAmt || 0) +
                    safeParseFloat(taxAmt || 0) +
                    safeParseFloat(deliveryFee || 0)
                  ).toFixed(2)}

                </div>
              </div>

              {handlePayment !== undefined && (
                <>
                  {!payLoading ? (
                    <button
                      className="btn btn-block mt-5"
                      onClick={() => handlePayment()}
                      disabled={enableBtn}
                    >
                      Pay now (GH₵{getCartProducts?.productsAmt})
                    </button>
                  ) : (
                    <button className="btn btn-block mt-5">
                      Redirecting... (GH₵{getCartProducts?.productsAmt})
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutPaySummary;
